<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewSettlementForm"
        ref="addNewSettlementForm"
        class="w-100 pt-6"
        @submit.prevent="submitNewSettlementForm"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="clientsList"
              label="Klient"
              v-model="clientId"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              placeholder="Szukaj klienta"
              append-icon="mdi-chevron-down"
              dense
            >
              <template #prepend-inner>
                <Icon
                  name="search"
                  size="small"
                  class="mr-2 mt-1"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="ordersList"
              label="Zlecenie"
              v-model="settlement.orderId"
              @change="setRequiredAmount"
              :rules="[rules.required]"
              :readonly="!clientId"
              item-text="parsedData"
              item-value="id"
              :placeholder="clientId ? 'Szukaj zlecenia' : 'Musisz wybrać klienta'"
              append-icon="mdi-chevron-down"
              no-data-text="Brak zleceń dla wybranego użytkownika"
              dense
            >
              <template #prepend-inner>
                <Icon
                  name="search"
                  size="small"
                  class="mr-2 mt-1"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-menu
              v-model="showDatePicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  :rules="showDatePicker ? [] : [rules.required]"
                  label="Data rozliczenia"
                  placeholder="Wybierz datę rozliczenia"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                  <template #append>
                    <SwitchDayButtons
                      v-model="settlement.depositedAt"
                      :min="minDate"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="settlement.depositedAt"
                v-bind="attributes.datePicker"
                :min="minDate"
                @input="showDatePicker = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-if="isOfficePayment"
              outlined
              :items="usersList"
              label="Pobierający"
              placeholder="Wybierz osobę pobierającą"
              v-model="settlement.userId"
              :item-text="item => `${item.firstName} ${item.lastName}`"
              item-value="id"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-radio-group
              v-model="isOfficePayment"
              class="mb-0"
              row
            >
              <v-radio
                label="Płatność w trasie"
                :value="false"
              />
              <v-radio
                label="Płatnośc w biurze"
                :value="true"
              />
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            v-if="isOfficePayment"
          >
            <v-text-field
              outlined
              label="Wpłacający"
              placeholder="Wpisz nazwisko osoby wpłacającej"
              :rules="[rules.required]"
              validate-on-blur
              v-model="settlement.clientName"
            />
          </v-col>

          <v-col
            cols="12"
            v-else
          >
            <v-autocomplete
              outlined
              :items="drivers"
              label="Kierowca"
              placeholder="Wybierz kierowcę"
              v-model="settlement.driverId"
              :item-text="item => `${item.firstName} ${item.lastName}`"
              item-value="id"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Pobrana kwota [zł]"
              placeholder="Wpisz wysokość pobranej kwoty"
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              validate-on-blur
              v-model.number="settlement.depositedAmount"
              type="number"
              @wheel="$event.target.blur()"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Pełna kwota [zł]"
              placeholder="Wpisz wysokość pełnej kwoty"
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              type="number"
              validate-on-blur
              v-model.number="settlement.requiredAmount"
              @wheel="$event.target.blur()"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-text-field
              outlined
              label="Notatka do rozliczenia"
              placeholder="Wpisz notatkę "
              v-model="settlement.description"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewSettlementForm"
      >
        Dodaj rozliczenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat, parseAsBasicUnit } from '../../utils'
import { mapState, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      attributes,
      isOfficePayment: false,
      showDatePicker: false,
      settlement: {
        orderId: null,
        driverId: null,
        userId: null,
        depositedAmount: null,
        requiredAmount: null,
        depositedAt: new Date().toISOString().substr(0, 10),
        description: null,
        clientName: null
      },
      clientId: null
    }
  },
  computed: {
    ...mapState({
      drivers: state => state.drivers.items,
      department: state => state.core.department,
      isProcessing: state => state.codDeposits.isProcessing,
      clientsList: state => state.clients.simplifiedList,
      ordersList: state => state.orders.simplifiedList,
      usersList: state => state.users.items
    }),
    minDate () {
      return DateTime.utc().toISODate()
    },
    formattedDate () {
      if (this.settlement.depositedAt) {
        return dateStringFormat(this.settlement.depositedAt)
      }
      return ''
    }
  },
  watch: {
    clientId (newValue) {
      const params = {
        departmentId: this.department.id,
        done: true,
        filters: {
          clientIds: [newValue]
        }
      }
      this.getSimplifiedOrders({ params })
    }
  },
  mounted () {
    this.getSimplifiedClients({ params: { filters: { withoutPatron: true }, departmentId: this.department.id } })
    this.getUsers()
  },
  methods: {
    ...mapActions({
      addNewCodDeposit: 'codDeposits/addNewItem',
      getCodDeposits: 'codDeposits/getItems',
      closeDialog: 'layout/closeDialog',
      getUsers: 'users/getItems',
      getSimplifiedOrders: 'orders/getSimplifiedItems',
      getSimplifiedClients: 'clients/getSimplifiedItems'
    }),
    setRequiredAmount (id) {
      this.settlement.requiredAmount = (this.ordersList.find(order => order.id === id).totalGrossValue / 100).toFixed(2)
    },

    submitNewSettlementForm () {
      if (this.$refs.addNewSettlementForm.validate()) {
        const params = {
          ...this.settlement,
          depositedAmount: parseAsBasicUnit(this.settlement.depositedAmount),
          requiredAmount: parseAsBasicUnit(this.settlement.requiredAmount)
        }
        if (this.isOfficePayment) {
          delete params.driverId
        } else {
          delete params.clientName
        }

        this.addNewCodDeposit({ params })
          .then(() => {
            this.getCodDeposits()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
</style>
